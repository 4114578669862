import React, { useCallback, useEffect, useRef, useState } from "react";
import { Group, Image, Rect, Text, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import useImage from "use-image";
import { MdDeleteOutline } from "react-icons/md";

const ImageItem = ({ img, isSelected, onSelect, onRemove }) => {
  const [image] = useImage(img.src);
  const imageRef = useRef();
  const transformerRef = useRef();
  const [position, setPosition] = useState({ x: img.x, y: img.y });
  useEffect(() => {
    if (isSelected && imageRef.current && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleDragMove = (e) => {
    const node = e.target;
    const newPos = { x: node.x(), y: node.y() };
    setPosition(newPos);
  };

  return (
    <React.Fragment>
      <Image
        image={image}
        x={img.x}
        y={img.y}
        width={img.width}
        height={img.height}
        draggable
        ref={imageRef}
        onClick={() => onSelect(img.id)}
        onDblClick={() => onRemove(img.id)}
        onDragMove={handleDragMove}
      />
      {isSelected && (
        <Html
          divProps={{
            style: {
              position: "absolute",
              left: `${position.x + img.width - 30}px`, // Dynamically update based on current position
              top: `${position.y - 30}px`,
              zIndex: 1,
            },
          }}
        >
          <MdDeleteOutline
            size={24}
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => onRemove(img.id)}
          />
        </Html>
      )}
      {isSelected && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => newBox}
        />
      )}
    </React.Fragment>
  );
};

const ImageElement = ({ selectedId, setSelectedId, images, onRemoveImage }) => {
  const handleSelect = useCallback(
    (id) => {
      console.log("Selecting:", id);
      if (selectedId !== id) {
        setSelectedId(id);
      }
    },
    [selectedId]
  );

  return images.map((img) => (
    <ImageItem
      key={img.id}
      img={img}
      isSelected={selectedId === img.id}
      onSelect={handleSelect}
      onRemove={onRemoveImage}
    />
  ));
};

export default ImageElement;
