import { CAlert, CCol } from "@coreui/react";
import "../styles/sidebar.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ImageGallery from "./ImageGallary";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const OtherImageGallary = (props) => {
  const [imageData, setImageData] = useState([]);
  const [loader, setLoader] = useState(false);
  const handleAddImage = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const newImage = {
        src: imageUrl,
        id: `imageUrl-${imageUrl.length}`,
        x: 50,
        y: 50,
        width: img.width, // Original width
        height: img.height, // Original height
      };
      props.setOtherImages([...props.otherImages, newImage]);
    };

    img.onerror = () => {
      console.error("Failed to load image:", imageUrl);
    };
  };
  useEffect(() => {
    getImageData(props.type);
  }, [props.type]);
  const getImageData = async (type) => {
    setImageData([]);
    setLoader(true);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_signed_url",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name: "croma_images",
        folder_name: type,
      },
    };

    try {
      // let response = tempResp;
      let response = await axios(config);
      console.log("response get", response);
      if (response != undefined) {
        setImageData(response.data.signed_urls);
        setLoader(false);
      }
    } catch (error) {
      console.log("data error", error);
      setLoader(false);
    }
  };
  return (
    <>
      <CCol className="w-100 position-relative">
        {loader ? (
          <div className="gallery">
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
          </div>
        ) : imageData.length == 0 ? (
          <CAlert className="px-2 py-1 m-2 mt-3">
            No{" "}
            {props.type == "backdrop_images"
              ? "Backdrop Images"
              : props.type == "fashion"
              ? "Product Images"
              : props.type == "logo_images"
              ? "Logo Images"
              : props.type == "furniture"
              ? "Stickers"
              : "Text"}{" "}
            available.
          </CAlert>
        ) : (
          <ImageGallery
            id={"otherImages"}
            images={imageData}
            setBackgroundImage={props.setBackgroundImage}
            handleAddImage={handleAddImage}
            type={props.type}
          />
        )}
      </CCol>
    </>
  );
};

export default OtherImageGallary;
