import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardTitle,
  CSpinner,
  CCardText,
  CButton,
  CToast,
  CToastBody,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableDataCell,
  CTooltip,
  CModalHeader,
  CModalBody,
  CModal,
  CAlert,
} from "@coreui/react";
import { CChartBar, CChartDoughnut } from "@coreui/react-chartjs";
import React, { useEffect, useRef } from "react";
import BarLoader from "./BarLoader";
// import "../../../scss/_custom.scss";
import "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import { Chart } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartAnnotation from "chartjs-plugin-annotation";

import { Bar, Line, getElementAtEvent } from "react-chartjs-2";
import { InfoTooltip } from "./InfoTooltip";
import CIcon from "@coreui/icons-react";
import {
  cilArrowLeft,
  cilBarChart,
  cilCheck,
  cilCloudDownload,
  cilFullscreen,
  cilX,
} from "@coreui/icons";
import { useState } from "react";
import { GraphBackArrow } from "./GraphBackArrow";
import ReactDOM from "react-dom";
import { CSVLink } from "react-csv";
import { ReactComponent as Drill } from "../assets/icons/drill-down-svgrepo-com.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  ChartAnnotation,
  Filler
);

const zoomOptions = {
  pan: {
    enabled: true,
    mode: "x",
  },
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
  },
};

const AdvanceBarChart = (props) => {
  const [chartData, setChartData] = useState(null);
  const [previousData, setPreviousData] = useState(null);
  const [hourChartData, setHourChartData] = useState(null);
  const [minChartData, setMinChartData] = useState(null);
  const [selectedDayValue, setSelectedDayValue] = useState(null);
  const [selectedValues, setSelectedValues] = useState({
    day: "",
    hour: "",
    min: "",
  });
  const [previousDayData, setPreviousDayData] = useState(null);
  const [previousHourData, setPreviousHourData] = useState(null);
  const [downloadData, setDownloadData] = useState({ data: [], name: "" });
  const csvLink = useRef(null);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const chartref = useRef();
  const [datasetIndex, setDatasetIndex] = useState();
  const [toastVisible, setToastVisible] = useState(false);
  const [partitionArr, setPartitionArr] = useState([]);
  const [downloadedArr, setDownloadedArr] = useState([]);
  const [loadedArr, setloadedArr] = useState([]);
  const [visible, setVisible] = useState(false);
  const [drillLevel, setDrillLevel] = useState(0);
  // console.log("selected value", props.downloadData);

  useEffect(() => {
    setMinChartData(null);
    setHourChartData(null);
    setPreviousDayData(null);
    setChartData(null);
    setPreviousData(null);
    setDrillLevel(0);
  }, [props.data]);

  // const downloadCsv = (csvString) => {
  //   var blob = new Blob([csvString]);
  //   if (window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveBlob(blob, "filename.csv");
  //   } else {
  //     var a = window.document.createElement("a");

  //     a.href = window.URL.createObjectURL(blob, {
  //       type: "text/plain",
  //     });
  //     a.download = "filename.csv";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //   }
  // };

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  function divideIntoPartitions(number) {
    let partitions = Math.ceil(number / 10000);
    let partitionList = [];

    for (let i = 0; i < partitions; i++) {
      let start = i * 10000;
      let end = Math.min((i + 1) * 10000, number);
      partitionList.push([start, end]);
    }

    return partitionList;
  }

  const handleGraphClick = async (event, activeElements) => {
    if (activeElements && activeElements.length > 0) {
      const activeElement = activeElements[0];
      const { index } = activeElement;
      var label = props.data.labels[index];
      props.handleDayClick(label);
    }
  };

  const handleDrillDown = async (event, activeElements) => {
    console.log("in handleDrillDown", drillLevel);
    setDrillLevel(1);
    if (event == "Widget") {
      let label = activeElements;

      setSelectedDayValue(label);
      setPreviousData(props.data);
      const daywiseData = await props.handleDrillDownData(label, 0);
      console.log("daywiseData", daywiseData);
      if (props.downloadstep1 && daywiseData != undefined) {
        setDownloadData(daywiseData);
        setDownloadReady(true);
        setChartData("download");
      } else {
        ReactDOM.unstable_batchedUpdates(() => {
          setChartData(daywiseData);
        });
      }
    } else if (activeElements && activeElements.length > 0) {
      const activeElement = activeElements[0];

      const { index } = activeElement;
      var label = props.data.labels[index];
      setSelectedValues({ ...selectedValues, day: label });

      var value = props.data.datasets[0].data[index];
      setSelectedDayValue(label);
      setPreviousData(props.data);

      const daywiseData = await props.handleDrillDownData(label, index);

      if (props.downloadstep1 && daywiseData != undefined) {
        setDownloadData(daywiseData);
        setDownloadReady(true);
        setChartData("download");
      } else {
        ReactDOM.unstable_batchedUpdates(() => {
          setChartData(daywiseData);
        });
      }
    }
  };
  const handleDayDrillDown = async (event, activeElements) => {
    console.log("in handleDayDrillDown", drillLevel);

    setDrillLevel(2);
    if (activeElements && activeElements.length > 0) {
      const activeElement = activeElements[0];

      const { index } = activeElement;
      var label = chartData.labels[index];
      let legend = chartData.datasets[datasetIndex ? datasetIndex : 0].label;
      setSelectedValues({ ...selectedValues, hour: label });
      setPreviousDayData(chartData);

      const newChartData1 = await props.handleDrillDownHour(
        selectedDayValue,
        label,
        index,
        legend
      );
      if (props.downloadstep2 && newChartData1 != undefined) {
        setDownloadData(newChartData1);
        setDownloadReady(true);
        setHourChartData("download");
      } else {
        ReactDOM.unstable_batchedUpdates(() => {
          setHourChartData(newChartData1);
        });
      }
    }
    // Modify the data structure for the drill-down level
  };

  const handleMinDrillDown = async (event, activeElements) => {
    console.log("in handleMinDrillDown", drillLevel);

    setDrillLevel(3);
    if (activeElements && activeElements.length > 0) {
      const activeElement = activeElements[0];

      console.log("in handleMinDrillDown", activeElements);

      const { index } = activeElement;
      var label = chartData.labels[index];
      let legend = chartData.datasets[datasetIndex ? datasetIndex : 0].label;
      setSelectedValues({ ...selectedValues, min: label });
      setPreviousHourData(chartData);

      const newChartData1 = await props.handleDrillDownMin(
        selectedDayValue,
        label,
        index,
        legend
      );
      if (props.downloadstep2 && newChartData1 != undefined) {
        setDownloadData(newChartData1);
        setDownloadReady(true);
        setMinChartData("download");
      } else {
        ReactDOM.unstable_batchedUpdates(() => {
          setMinChartData(newChartData1);
        });
      }
    }
  };

  const onClick = (e) => {
    const elem = getElementAtEvent(chartref.current, e);
    setDatasetIndex(elem[0]?.datasetIndex);
  };

  const handleHourGoBack = () => {
    if (previousDayData) {
      setHourChartData(null);
      setPreviousDayData(null);
      setDrillLevel(1);
    }
  };
  const handleMinGoBack = () => {
    if (previousDayData) {
      setMinChartData(null);
      setPreviousHourData(null);
      setDrillLevel(2);
    }
  };

  const handleGoBack = () => {
    if (previousData) {
      setChartData(null);
      setPreviousData(null);
      setDrillLevel(0);
    }
  };
  const handleScroll = (e) => {
    if (e.deltaY > 0) {
      document.getElementById(props.label).style.cursor = "zoom-out";
    } else if (e.deltaY < 0) {
      document.getElementById(props.label).style.cursor = "zoom-in";
    } else {
      document.getElementById(props.label).style.cursor = "default";
    }
  };
  const handleGraphDownloadData = () => {
    if (minChartData && minChartData.downloadData) {
      setDownloadData(minChartData.downloadData);
      setDownloadReady(true);
    } else if (hourChartData && hourChartData.downloadData) {
      setDownloadData(hourChartData.downloadData);
      setDownloadReady(true);
    } else if (chartData && chartData.downloadData) {
      setDownloadData(chartData.downloadData);
      setDownloadReady(true);
    } else if (!hourChartData && !chartData && props.downloadData) {
      console.log("props.downloadData", props.downloadData);
      setDownloadData(props.downloadData);
      setDownloadReady(true);
    } else {
      setToastVisible(true);
    }
  };
  const DisplyLoader = () => {
    return (
      <CCol style={{ overflow: "hidden" }}>
        <CCard>
          <CCardBody className="p-2">
            <CCol className="position-relative">
              {props.label.length != 0 ? (
                <CCardTitle
                  className={`datageir-graph-label fs-6 p-0 ${
                    props.titleCenter ? "d-flex justify-content-center" : null
                  }`}
                >
                  {`${
                    !props.titleStart
                      ? ""
                      : drillLevel == 0
                      ? "Day Wise "
                      : drillLevel == 1
                      ? "Hour Wise "
                      : drillLevel == 2
                      ? "Minut Wise "
                      : drillLevel == 3
                      ? "Second Wise "
                      : ""
                  }${props.label}`}
                </CCardTitle>
              ) : null}
              {props.disableInfo ? null : <InfoTooltip info={props.info} />}
            </CCol>
            <CCol
              className="p-1 m-0"
              style={{ height: `${props.height - 1.5}vh` }}
            >
              <BarLoader height={"50"} width={"30"} />
            </CCol>
            {/* <CCol>
              {props.height == undefined ? (
                <CChartBar />
              ) : (
                <CChartBar height={`${props.height}`} />
              )}
            </CCol> */}
          </CCardBody>
        </CCard>
      </CCol>
    );
  };
  const getAdvancedBarChart = (height) => (
    <>
      {/* for download graph data */}
      <CSVLink
        filename={`${downloadData.tableTitle}.csv`}
        data={downloadData.data}
        ref={csvLink}
        headers={downloadData.tableheader}
      ></CSVLink>
      <CToast
        autohide={true}
        delay={2500}
        className="domain-toast-position admin-toast-style border-top border-right border-left border-bottom-3 border-success rounded-top"
        visible={toastVisible}
        style={{ zIndex: 1 }}
        onClose={() => {
          setToastVisible(false);
        }}
      >
        <div>
          <CToastBody style={{ display: "flex", justifyContent: "center" }}>
            Coming Soon!!
          </CToastBody>
        </div>
      </CToast>
      {props.data == undefined ? (
        <DisplyLoader />
      ) : chartData == undefined && previousData != undefined ? (
        <DisplyLoader />
      ) : previousDayData != undefined && hourChartData == undefined ? (
        <DisplyLoader />
      ) : previousHourData != undefined && minChartData == undefined ? (
        <DisplyLoader />
      ) : minChartData == "download" ||
        hourChartData == "download" ||
        chartData == "download" ? (
        <CCol>
          <CCard className="height-100">
            <CCardBody className="p-2">
              <CRow>
                <CCol>
                  {previousData && !previousDayData ? (
                    <GraphBackArrow handleBack={handleGoBack}></GraphBackArrow>
                  ) : previousDayData ? (
                    <GraphBackArrow
                      handleBack={handleHourGoBack}
                    ></GraphBackArrow>
                  ) : null}
                </CCol>
                <CCol>
                  {props.label.length != 0 ? (
                    <CCardTitle
                      className={`datageir-graph-label fs-6  p-0${
                        props.resizeDisabled
                          ? null
                          : visible
                          ? null
                          : "cursor-resize"
                      }  ${
                        props.titleCenter
                          ? "d-flex justify-content-center"
                          : null
                      }`}
                      onClick={() =>
                        props.resizeDisabled ? null : setVisible(true)
                      }
                    >
                      {`${
                        !props.titleStart
                          ? ""
                          : drillLevel == 0
                          ? "Day Wise "
                          : drillLevel == 1
                          ? "Hour Wise "
                          : drillLevel == 2
                          ? "Minut Wise "
                          : drillLevel == 3
                          ? "Second Wise "
                          : ""
                      }${props.label}`}
                    </CCardTitle>
                  ) : null}
                </CCol>
                <CCol>
                  {props.disableInfo ? null : <InfoTooltip info={props.info} />}
                </CCol>
              </CRow>
              <CCol>
                <CCardText id="traffic" className="datageir-downloaded">
                  CSV File Downloaded
                </CCardText>
                <CCol>
                  <CChartBar style={{ height: "20vh" }} />
                </CCol>
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
      ) : props.data.datasets[0].data.length == 0 ||
        (drillLevel == 1 && chartData.datasets[0].data.length == 0) ||
        (drillLevel == 2 && hourChartData.datasets[0].data.length == 0) ||
        (drillLevel == 3 && minChartData.datasets[0].data.length == 0) ? (
        <CCol style={{ overflow: "hidden" }}>
          <CCard>
            <CCardBody className="p-2">
              <CCol className="d-flex align-items-center gap-2">
                {previousData && !previousDayData ? (
                  <GraphBackArrow handleBack={handleGoBack}></GraphBackArrow>
                ) : previousDayData ? (
                  <GraphBackArrow
                    handleBack={handleHourGoBack}
                  ></GraphBackArrow>
                ) : null}
                {props.label.length != 0 ? (
                  <CCardTitle
                    className={`datageir-graph-label fs-6 p-0${
                      props.resizeDisabled
                        ? null
                        : visible
                        ? null
                        : "cursor-resize"
                    }  ${
                      props.titleCenter ? "d-flex justify-content-center" : null
                    }`}
                    onClick={() =>
                      props.resizeDisabled ? null : setVisible(true)
                    }
                  >
                    {`${
                      !props.titleStart
                        ? ""
                        : drillLevel == 0
                        ? "Day Wise "
                        : drillLevel == 1
                        ? "Hour Wise "
                        : drillLevel == 2
                        ? "Minut Wise "
                        : drillLevel == 3
                        ? "Second Wise "
                        : ""
                    }${props.label}`}
                  </CCardTitle>
                ) : null}
              </CCol>
              <CCol>
                {props.disableInfo ? null : <InfoTooltip info={props.info} />}
              </CCol>

              <CCol style={{ height: height ? `${height - 1}vh` : "70vh" }}>
                {props.filterResult && !props.filterResult.warehouse ? (
                  <CAlert
                    color="info"
                    className="m-2 p-2 font-sm text-center d-flex justify-content-center"
                  >
                    The graph is accessible only when warehouses are exclusively
                    linked to the tag.
                  </CAlert>
                ) : (
                  <CCardText id="traffic" className="datageir-no-data">
                    No Data To Show
                  </CCardText>
                )}
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
      ) : props.data.labels.length == 1 &&
        !chartData &&
        props.noWidget == undefined ? (
        <CCol className="height-100">
          <CCard className="height-100 position-relative">
            <CCardBody className="chart-height p-1">
              <CCol>
                {props.label.length != 0 ? (
                  <CCardTitle
                    className={`datageir-graph-label fs-6 p-0 ${
                      props.resizeDisabled
                        ? null
                        : visible
                        ? null
                        : "cursor-resize"
                    }  ${
                      props.titleCenter ? "d-flex justify-content-center" : null
                    }`}
                    onClick={() =>
                      props.resizeDisabled ? null : setVisible(true)
                    }
                  >
                    {`${props.label}`}
                    {props.drillDownLevel != 0 ? (
                      <CTooltip
                        className="graph-tooltip"
                        arrow={true}
                        content={"Explore Data with Drill Down"}
                      >
                        <Drill
                          className="position-absolute text-success"
                          width={14}
                          height={14}
                          style={{
                            right: "25px",
                            top: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </CTooltip>
                    ) : null}

                    {props.disableDownload ? null : (
                      <CIcon
                        onClick={handleGraphDownloadData}
                        icon={cilCloudDownload}
                        color="success"
                        className="position-absolute text-success"
                        width={14}
                        height={14}
                        style={{ right: "25px", top: "5px", cursor: "pointer" }}
                      />
                    )}
                    {props.disableInfo ? null : (
                      <InfoTooltip info={props.info} />
                    )}
                  </CCardTitle>
                ) : null}
              </CCol>
              {props.label.length != 0 && props.subTitleStart ? (
                <CRow className="mb-0">
                  <CCol className={`font-10 ps-4 mb-0 `}>
                    {`${
                      !props.subTitleStart
                        ? ""
                        : drillLevel == 0
                        ? "Day Wise "
                        : drillLevel == 1
                        ? "Hour Wise "
                        : drillLevel == 2
                        ? "Minut Wise "
                        : drillLevel == 3
                        ? "Second Wise "
                        : ""
                    }${props.label}${
                      !props.subTitleStart
                        ? ""
                        : drillLevel == 0
                        ? ""
                        : drillLevel == 1
                        ? ` -  ${selectedValues.day}`
                        : drillLevel == 2
                        ? ` -  ${selectedValues.day} ${selectedValues.hour}h`
                        : drillLevel == 3
                        ? ` -  ${selectedValues.day} ${selectedValues.hour}h ${selectedValues.min}m`
                        : ""
                    }`}
                  </CCol>
                </CRow>
              ) : null}
              <CCol className=" m-0 p-0 pt-4 position-relative  d-flex justify-content-center">
                <CCol
                  xs={12}
                  className="d-flex flex-column justify-content-center align-items-center gap-2"
                  style={{ height: "max-content" }}
                >
                  <CIcon
                    style={{ color: "rgba(50,31,219)", cursor: "pointer" }}
                    icon={cilBarChart}
                    width={25}
                    height={25}
                    // onClick={() => { handleDrillDown("Widget",props.data.labels[0])}}
                    // hourChartData
                    //   ? null
                    //   : chartData
                    //   ? handleDayDrillDown
                    //   : handleDrillDown("Widget",props.data.labels[0])}
                    onClick={() =>
                      hourChartData
                        ? null
                        : chartData && props.drillDownLevel > 1
                        ? handleDayDrillDown
                        : props.drillDownLevel >= 1
                        ? handleDrillDown("Widget", props.data.labels[0])
                        : null
                    }
                    className="m-0 p-0"
                  />
                  <CCol xs={12} className="m-0 p-0">
                    {(props.unit == "$" || props.unit == "GB") &&
                    props.unit != "" ? (
                      <CCardText
                        className="m-0 p-0 d-flex justify-content-center align-items-center gap-2"
                        style={{
                          fontSize: "35px",
                          color: "rgba(20,173,135,0.3)",
                          fontWeight: 900,
                          textAlign: "center",
                          lineHeight: "41px",
                          "-webkit-text-stroke": "1px rgb(20,173,135)",
                        }}
                      >
                        <span>{props.unit}</span>
                        <span>
                          {Number(
                            props.data.datasets[0].data[0]
                          ).toLocaleString()}
                        </span>
                      </CCardText>
                    ) : (
                      <div className="m-0 p-0 d-flex flex-column justify-content-center align-items-center">
                        <CCardText
                          className="m-0 p-0"
                          style={{
                            fontSize: "13px",
                            color: "rgb(32,86,151,0.7)",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          {props.unit}
                        </CCardText>
                        <CCardText
                          className="m-0 p-0 d-flex justify-content-center align-items-center"
                          style={{
                            fontSize: "35px",
                            color: "rgba(20,173,135,0.3)",
                            fontWeight: 900,
                            textAlign: "center",
                            lineHeight: "41px",
                            "-webkit-text-stroke": "1px rgb(20,173,135)",
                          }}
                        >
                          <span>
                            {" "}
                            {Number(
                              props.data.datasets[0].data[0]
                            ).toLocaleString()}
                          </span>
                        </CCardText>
                      </div>
                    )}

                    <CCardText
                      className="m-0 p-0 mt-2"
                      style={{
                        fontSize: "13px",
                        color: "rgb(32,86,151,0.7)",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      {props.data.labels[0]}
                    </CCardText>
                  </CCol>
                </CCol>
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
      ) : (
        <CCol>
          <CCard>
            <CCardBody className="chart-height p-1">
              <CRow>
                <CCol xs={12} className="d-flex align-items-center gap-2">
                  <CCol>
                    {previousData && !previousDayData ? (
                      <GraphBackArrow
                        handleBack={handleGoBack}
                      ></GraphBackArrow>
                    ) : previousDayData && !previousHourData ? (
                      <GraphBackArrow
                        handleBack={handleHourGoBack}
                      ></GraphBackArrow>
                    ) : previousHourData ? (
                      <GraphBackArrow
                        handleBack={handleMinGoBack}
                      ></GraphBackArrow>
                    ) : null}
                  </CCol>
                  <CCol xs={12}>
                    {props.label.length != 0 ? (
                      <CCardTitle
                        className={`datageir-graph-label fs-6 p-0 ${
                          props.resizeDisabled
                            ? null
                            : visible
                            ? null
                            : "cursor-resize"
                        }  ${
                          props.titleCenter
                            ? "d-flex justify-content-center"
                            : null
                        }`}
                        onClick={() =>
                          props.resizeDisabled ? null : setVisible(true)
                        }
                      >
                        {`${props.label}`}
                      </CCardTitle>
                    ) : null}
                  </CCol>
                </CCol>

                <CCol>
                  {props.drillDownLevel != 0 &&
                  drillLevel != props.drillDownLevel ? (
                    <CTooltip
                      className="graph-tooltip"
                      arrow={true}
                      content={"Explore Data with Drill Down"}
                    >
                      <Drill
                        className="position-absolute text-success"
                        width={14}
                        height={14}
                        style={{ right: "25px", top: "5px", cursor: "pointer" }}
                      />
                    </CTooltip>
                  ) : null}

                  {props.disableDownload ? null : (
                    <CIcon
                      onClick={handleGraphDownloadData}
                      icon={cilCloudDownload}
                      color="info"
                      className="position-absolute text-success"
                      width={14}
                      height={14}
                      style={{ right: "25px", top: "5px", cursor: "pointer" }}
                    />
                  )}
                  {props.disableInfo ? null : <InfoTooltip info={props.info} />}
                </CCol>
              </CRow>

              {props.label.length != 0 && props.subTitleStart ? (
                <CRow className="mb-0">
                  <CCol className={`font-10 ps-4 mb-0 `}>
                    {`${
                      !props.subTitleStart
                        ? ""
                        : drillLevel == 0
                        ? "Day Wise "
                        : drillLevel == 1
                        ? "Hour Wise "
                        : drillLevel == 2
                        ? "Minut Wise "
                        : drillLevel == 3
                        ? "Second Wise "
                        : ""
                    }${props.label}${
                      !props.subTitleStart
                        ? ""
                        : drillLevel == 0
                        ? ""
                        : drillLevel == 1
                        ? ` -  ${selectedValues.day}`
                        : drillLevel == 2
                        ? ` -  ${selectedValues.day} ${selectedValues.hour}h`
                        : drillLevel == 3
                        ? ` -  ${selectedValues.day} ${selectedValues.hour}h ${selectedValues.min}m`
                        : ""
                    }`}
                  </CCol>
                </CRow>
              ) : null}

              <CCol
                style={{
                  height: height ? `${height}vh` : "70vh",
                  position: "relative",
                }}
              >
                <Line
                  id={props.label}
                  // id={"myChart"}
                  onWheelCapture={(e) => {
                    handleScroll(e);
                  }}
                  onClick={onClick}
                  ref={chartref}
                  style={{ cursor: "pointer" }}
                  data={
                    minChartData
                      ? minChartData
                      : hourChartData
                      ? hourChartData
                      : chartData
                      ? chartData
                      : props.data
                  }
                  height={height}
                  //options={options}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                      padding: {
                        top: 0,
                      },
                    },

                    interaction: {
                      mode: props.singleval ? "point" : "index",
                      intersect: false,
                    },
                    onClick:
                      hourChartData &&
                      props.drillDownLevel >= 3 &&
                      !minChartData
                        ? handleMinDrillDown
                        : hourChartData
                        ? null
                        : chartData && props.drillDownLevel > 1
                        ? handleDayDrillDown
                        : !chartData && props.drillDownLevel >= 1
                        ? handleDrillDown
                        : !chartData && props.drillDownLevel < 0
                        ? handleGraphClick
                        : null,
                    plugins: {
                      annotation: {
                        annotations:
                          props.annotation && props.annotationType == undefined
                            ? [
                                {
                                  type: "line",
                                  borderColor: "red",
                                  borderWidth: 1,
                                  display: (ctx) =>
                                    ctx.chart.isDatasetVisible(1),
                                  label: {
                                    display: true,
                                    content: "Contract End",
                                    position: "start",
                                  },
                                  scaleID: "x",
                                  value:
                                    drillLevel == 0 && props.annotationValue
                                      ? props.annotationValue
                                          .contractDateMonthYear
                                      : props.annotationValue &&
                                        props.selectedMonth ==
                                          props.annotationValue.contractEndDate.split(
                                            "-"
                                          )[1]
                                      ? props.annotationValue.contractEndDate
                                      : null,
                                },
                              ]
                            : props.annotation &&
                              props.annotationType == "multiple"
                            ? { ...props.annotationValue }
                            : null,
                      },
                      datalabels: {
                        display: props.dataLabels ? true : false,
                        // formatter: Math.round,
                        // color: "#7B68EE",
                        // align: "end",
                        // anchor: "end",
                        // offset: -2,
                        // font: { size: "10.5" }
                      },
                      tooltip: {
                        position: "average",
                        callbacks: {
                          beforeFooter: (context) => {
                            if (
                              props.customTooltip &&
                              props.data.tooltip == undefined
                            ) {
                              return `Total ${
                                hourChartData
                                  ? hourChartData.additionaldata[2]
                                  : chartData
                                  ? chartData.additionaldata[2]
                                  : props.data.additionaldata[2]
                              }: ${
                                hourChartData
                                  ? hourChartData.additionaldata[0][
                                      context[0].dataIndex
                                    ].toLocaleString()
                                  : chartData
                                  ? chartData.additionaldata[0][
                                      context[0].dataIndex
                                    ].toLocaleString()
                                  : props.data.additionaldata[0][
                                      context[0].dataIndex
                                    ].toLocaleString()
                              } \nTotal Cost $:${
                                hourChartData
                                  ? hourChartData.additionaldata[1][
                                      context[0].dataIndex
                                    ].toLocaleString()
                                  : chartData
                                  ? chartData.additionaldata[1][
                                      context[0].dataIndex
                                    ].toLocaleString()
                                  : props.data.additionaldata[1][
                                      context[0].dataIndex
                                    ].toLocaleString()
                              }`;
                            } else if (
                              props.customTooltip &&
                              props.data.tooltip
                            ) {
                              return `WH size: ${
                                props.data.tooltip[context[0].dataIndex]
                              }`;
                            }
                          },
                        },
                      },
                      legend: {
                        align: "start",
                        labels: {
                          boxWidth: props.showLegend ? 12 : 0,
                          boxHeight: props.showLegend ? 8 : 0,
                          font: {
                            size: 9,
                          },
                          textAlign: "left",
                          align: "start",
                        },
                        display:
                          minChartData ||
                          hourChartData ||
                          chartData ||
                          props.showLegend
                            ? true
                            : false,
                        maxWidth: 20,
                        font: {
                          size: 9,
                        },
                      },
                      zoom: zoomOptions,
                      subtitle: {
                        display:
                          props.subtitle && chartData
                            ? true
                            : props.subtitle && props.drillDownLevel == 0
                            ? true
                            : false,
                        text:
                          chartData != null && chartData.subtitle
                            ? chartData.subtitle
                            : props.subtitle && props.drillDownLevel == 0
                            ? props.subtitle
                            : "",
                        font: {
                          size: 9,
                        },
                      },
                    },
                    scales: {
                      x: {
                        stacked: props.stacked
                          ? true
                          : props.stackedx
                          ? true
                          : false,
                        ticks: {
                          font: {
                            size: 9,
                          },
                        },
                        title: {
                          display: props.xLable != undefined ? true : false,
                          text: props.xLable,
                          padding: 4,
                          font: {
                            size: 10,
                          },
                        },
                      },
                      y: {
                        type: "linear",
                        display: true,
                        position: "left",
                        stacked: props.stacked
                          ? true
                          : props.stackedx
                          ? false
                          : false,
                        ticks: {

                          // max: Math.max(...props.data.datasets[0].data) * 1.2,
                          font: {
                            size: 9,
                          },
                          
                        },
                        reverse: props.reverse?true:false
                      },
                      y1: {
                        type: "linear",
                        display: props.dualAxes ? true : false,
                        position: "right",
                        // grid line settings
                        grid: {
                          drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        ticks: {
                          // max: Math.max(...props.data.datasets[0].data) * 1.2,
                          font: {
                            size: 9,
                          },
                        },
                      },
                    },
                  }}
                ></Line>
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
      )}
    </>
  );
  return (
    <>
      {/* <CModal visible={visible} onClose={() => setVisible(false)} size="xl">
        <CModalHeader onClose={() => setVisible(false)}></CModalHeader>
        <CModalBody>{getAdvancedBarChart()}</CModalBody>
      </CModal> */}
      {getAdvancedBarChart(props.height)}
    </>
  );
};
export default React.memo(AdvanceBarChart);
