import { CCol } from "@coreui/react";
import { PiTextTBold } from "react-icons/pi";
import { FaBold } from "react-icons/fa";
import { FaItalic } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

const fontList = [
  // Web-safe fonts
  "Arial",
  "Verdana",
  "Helvetica",
  "Tahoma",
  "Trebuchet MS",
  "Times New Roman",
  "Georgia",
  "Garamond",
  "Courier New",
  "Brush Script MT",

  // Google Fonts
  "Roboto",
  "Open Sans",
  "Lato",
  "Montserrat",
  "Oswald",
  "Raleway",
  "Poppins",
  "Merriweather",
  "Ubuntu",
  "Playfair Display",
  "Nunito",
  "PT Serif",
  "Dancing Script",
  "Pacifico",
  "Abril Fatface",

  // System fonts
  "Segoe UI",
  "Lucida Grande",
  "Geneva",
  "Cambria",
  "Palatino Linotype",
];
const TextToolbar = (props) => {
  const handleAddText = () => {
    const newText = {
      id: uuidv4(),
      text: "Sample Text",
      x: 50,
      y: 50,
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "sans-serif",
      fill: "black",
      width: 200,
      height: 30,
      isEditing: false,
      type: "text",
    };
    props.setTexts([...props.texts, newText]);
  };
  const handleStyleChange = (property, value) => {
    if (props.selectedTextId) {
      updateText(props.selectedTextId, { [property]: value });
    }
  };

  const updateText = (id, newProps) => {
    props.setTexts(
      props.texts.map((text) =>
        text.id === id ? { ...text, ...newProps } : text
      )
    );
  };

  return (
    <CCol>
      <CCol
        style={{
          border: "1px solid lightgray",
          borderRadius: "5px",
          backgroundColor: "lightgray",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 2px -2px, rgba(0, 0, 0, 0.3) 0px 2px 4px -3px",
          cursor: "pointer",
          fontWeight: "500",
        }}
        onClick={handleAddText}
        className="p-2 px-3 m-2 mx-3  d-flex justify-content-between align-items-center"
      >
        Add Text <PiTextTBold size={20} />
      </CCol>
      <CCol className="p-2 px-3 m-2 d-flex flex-column">
        <CCol className="d-flex flex-column mb-3">
          <label className="mb-1">Font: </label>
          <select
            value={props.selectedText?.fontFamily}
            style={{ borderRadius: "5px" }}
            onChange={(e) => handleStyleChange("fontFamily", e.target.value)}
          >
            {fontList.map((font) => (
              <option key={font} value={font} style={{ fontFamily: font }}>
                {font}
              </option>
            ))}
          </select>
        </CCol>
        <CCol className="mt-2 mb-3 d-flex align-items-center gap-2">
          <label>Size: </label>
          <input
            style={{ width: "60px", borderRadius: "5px" }}
            type="number"
            value={props.selectedText?.fontSize}
            onChange={(e) =>
              handleStyleChange("fontSize", parseInt(e.target.value))
            }
          />
        </CCol>
        <CCol className="mb-3">
          <label>Style:</label>
          <CCol className="mt-2 d-flex align-items-center gap-2">
            <div
              style={{
                width: "fit-content",
                border: "1px solid lightgray",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              className={`p-1 px-2 d-flex align-items-center text-style-btn  ${
                props.selectedText?.fontWeight === "bold" &&
                "text-style-btn-active"
              }`}
              onClick={() =>
                handleStyleChange(
                  "fontWeight",
                  props.selectedText?.fontWeight === "bold" ? "normal" : "bold"
                )
              }
            >
              <FaBold size={18} />
            </div>

            {/* Italic button */}
            <div
              style={{
                width: "fit-content",
                border: "1px solid lightgray",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              className={`p-1 px-2 d-flex align-items-center text-style-btn  ${
                props.selectedText?.fontStyle === "italic" &&
                "text-style-btn-active"
              }`}
              onClick={() =>
                handleStyleChange(
                  "fontStyle",
                  props.selectedText?.fontStyle === "italic"
                    ? "normal"
                    : "italic"
                )
              }
            >
              <FaItalic size={18} />
            </div>
          </CCol>
        </CCol>

        <label className="mb-1">Color: </label>
        <input
          type="color"
          value={props.selectedText?.fill}
          onChange={(e) => handleStyleChange("fill", e.target.value)}
        />
      </CCol>
    </CCol>
  );
};

export default TextToolbar;
