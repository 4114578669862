import { cilArrowLeft, cilInfo } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

export const GraphBackArrow = ({ handleBack }) => {
  return (
    <CIcon
      color="info"
      width={13}
      height={13}
      style={{ position: "relative", cursor: "pointer", top: "-5px" }}
      icon={cilArrowLeft}
      onClick={handleBack}
    ></CIcon>
  );
};
