import React from "react";
import { FaSquareMinus, FaRegSquarePlus } from "react-icons/fa6";

const CheckBox = ({ onChange, checked, label }) => {
  return (
    <div
      style={{ cursor: "default", width: "max-content" }}
      onClick={onChange}
      className="font-md d-flex gap-1"
    >
      {checked ? (
        <FaSquareMinus style={{ color: "#ad8579" }} size={"1rem"} />
      ) : (
        <FaRegSquarePlus style={{ color: "#ad8579" }} size={"1rem"} />
      )}
      {label && label}
    </div>
  );
};

export default CheckBox;
