import {
  CAlert,
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CFormTextarea,
  CSpinner,
} from "@coreui/react";
import "../styles/sidebar.css";
import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilSend } from "@coreui/icons";
import ImageGallery from "./ImageGallary";
import axios from "axios";
const aspectRatio = ["1:1", "3:4", "4:3", "9:16", "16:9"];
const ImageGenerator = (props) => {
  const [saveLoader, setSaveLoader] = useState(false);
  const [savedAlert, setSavedAlert] = useState("");
  const handleSave = async () => {
    setSavedAlert("");
    setSaveLoader(true);
    const fileNames = props.selectedImages.map((image) => image.fileName);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_image_generation",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        selected_image_ids: fileNames,
      },
    };

    try {
      // let response = tempResp;
      let response = await axios(config);
      console.log("response", response);
      if (response != undefined) {
        setSaveLoader(false);
        setSavedAlert("imageSaved");
      }
    } catch (error) {
      console.log("data error", error);
      setSavedAlert("");
      setSaveLoader(false);
    }
  };

  const handleTypeSelect = () => {};
  return (
    <>
      <CCol
        className="w-100 position-relative px-2 h-50"
        style={{ overflow: "hidden" }}
      >
        <CForm className="pt-3" onSubmit={(e) => props.handlePrompt(e)}>
          <CCard
            style={{
              backgroundColor: "transparent",
              border: "none",
              width: "100%",
              padding: "2px",
              borderRadius: "50px",
            }}
          >
            <CCol
              className="d-flex flex-column w-100 inputfocus  "
              style={{
                backgroundColor: "white",
                border: "none",
              }}
            >
              <CFormTextarea
                className=" inputfocus form-control font-md"
                style={{
                  backgroundColor: "white",
                  boxShadow: "none",
                  color: "#086EB5",
                  borderRadius: "5px",
                }}
                rows={4}
                onChange={(e) => {
                  e.preventDefault();
                  props.setInputVal(e.target.value);
                }}
                value={props.inputVal}
                placeholder={"Enter prompt"}
                disabled={props.loading}
              />
            </CCol>
          </CCard>
          <CCol>
            <div className="d-flex  align-items-center flex-wrap ">
              <span style={{ fontSize: "13px" }} className="me-2">
                Aspect Ratio:
              </span>
              {aspectRatio.map((item, innInd) => (
                <label key={`${item}-${innInd}`}>
                  <input
                    type="radio"
                    name={`exampleRadios-${item}-${innInd}`}
                    className="card-input-element"
                    checked={item == props.aspectRatio}
                    onChange={() => props.setAspectRatio(item)}
                    disabled={props.loading}
                  />
                  <div
                    className={`p-0 chat-message other d-flex align-items-center justify-content-center card-input`}
                    style={{
                      margin: "0px",
                      color: "#19a353",
                      fontWeight: "normal",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      width: "45px",
                    }}
                  >
                    {item}
                  </div>
                </label>
              ))}
            </div>
            <div className="d-flex  align-items-center flex-wrap mt-3">
              <span style={{ fontSize: "13px" }} className="me-2">
                Number of Images:
              </span>
              <select
                style={{ width: "60px", fontSize: "12px" }}
                className="form-select p-0 ps-2"
                aria-label="Disabled select example"
                onChange={(e) => props.setImageCount(e.target.value)}
                value={props.imageCount}
              >
                {Array.from({ length: 4 }, (_, i) => (
                  <option value={i + 1}>{i + 1}</option>
                ))}
              </select>
            </div>
          </CCol>
          <CButton
            type="submit"
            disabled={props.inputVal == "" || props.loading}
            color="success"
            size="sm"
            className=" d-flex align-items-center justify-content-center gap-2 p-0 py-1  hover w-100"
          >
            {props.loading && <CSpinner size="sm" />}
            Generate
          </CButton>
        </CForm>
        {props.selectedImages.length > 0 && (
          <CCol className="d-flex flex-column">
            <CCol
              className="mb-1"
              style={{ fontSize: "14px", fontWeight: "500" }}
            >
              Selected Images
            </CCol>
            <CCol
              className="p-1"
              style={{
                border: "1px solid lightgrey",
                borderRadius: "5px",

                maxHeight: "29vh",
                overflow: "scroll",
              }}
            >
              <ImageGallery
                id={"imageGenerator"}
                images={props.selectedImages}
                selectedImages={props.selectedImages}
                setSelectedImages={props.setSelectedImages}
              />
            </CCol>
            {savedAlert == "imageSaved" && (
              <CAlert
                className="mt-2 p-0 px-2 py-1"
                style={{ fontSize: "12px" }}
              >
                Images Saved Successfully!
              </CAlert>
            )}

            {savedAlert == "imageSaved" && (
              <CAlert
                className="mt-2 p-0 px-2 py-1"
                style={{ fontSize: "12px" }}
              >
                View the saved image in other tabs
              </CAlert>
            )}
            <CCol className="d-flex justify-content-end mt-2">
              <CButton
                disabled={saveLoader}
                color="success"
                size="sm"
                className=" d-flex align-items-center justify-content-center gap-2 p-0 py-1 px-4  hover "
                onClick={() => handleSave()}
              >
                {saveLoader && <CSpinner size="sm" />}
                Save
              </CButton>
            </CCol>
          </CCol>
        )}
      </CCol>
    </>
  );
};

export default ImageGenerator;
