import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import {
  CForm,
  CCol,
  CFormInput,
  CButton,
  CSpinner,
  CCard,
  CRow,
  CImage,
  CAvatar,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { FcDataSheet, FcSearch, FcPanorama, FcUpload } from "react-icons/fc";
import { cilSend } from "@coreui/icons";
import { ChatMessage } from "../components/ChatMessage";
import axios from "axios";
import CsvSidebar from "../components/CsvSidebar";
import { IoIosChatbubbles } from "react-icons/io";
import CommonSidebar from "../components/CommonSidebar";
import { BsFillFolderSymlinkFill } from "react-icons/bs";
import UploadCsv from "../components/UploadCsv";
import csvUpload from "../assets/images/csv_icon.png";
import aiAvatarImage from "../assets/images/OIG.png";
import aiImage from "../assets/images/ai_image.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import arcDiag from "../assets/images/csv_arch_dia.png";
const CsvPrompt = () => {
  const region = JSON.parse(sessionStorage.getItem("region"));
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [generatedData, setGeneratedData] = useState([]);
  const [promptList, setPromptList] = useState([]);
  const [indexed, setIndexed] = useState(false);
  const messageContainerRef = useRef(null);
  const [urlGeneratedData, setUrlGeneratedData] = useState([]);
  const [blobName, setBlobName] = useState();
  const [csvFile, setCsvFile] = useState([]);
  const [DocNameList, setDocNameList] = useState([]);
  const [visible, setVisible] = useState(true);

  // useState([{
  //   question:"Tell about website",
  //   answer:'The website is about web development. It provides tutorials, exercises, quizzes, and certifications for HTML, CSS, Bootstrap, Data Analytics, and more.'
  // }]);

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight + 250,
        behavior: "smooth",
      });
    }
  };

  const uploadDocument = async () => {
    if (csvFile.length > 0) {
      let data = JSON.stringify({
        csv: csvFile,
        project: "generativeai-coe",
        location: "us-central1",
        csv_upload: true,
      });
      let config = {
        method: "post",
        url:
          region == "IND"
            ? "https://us-central1-generativeai-coe.cloudfunctions.net/talk-to-csv"
            : "https://us-central1-generativeai-coe.cloudfunctions.net/talk-to-csv",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        data,
      };
      try {
        let response = await axios(config);
        if (response != undefined) {
          setBlobName(response.data.blob_name);
        }
      } catch (err) {
        console.log("Response Error", err);
      }
    }
  };

  const deleteDocument = async () => {
    setUrlGeneratedData([]);
    setPromptList([]);
    setPrompt("");
    setCsvFile([]);
    setDocNameList([]);
    let data = JSON.stringify({
      csv_delete: true,
      project: "generativeai-coe",
      location: "us-central1",
      blob_name: blobName,
    });
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-generativeai-coe.cloudfunctions.net/talk-to-csv"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/talk-to-csv",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data,
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
      }
    } catch (err) {
      console.log("Response Error", err);
    }
  };

  useEffect(() => {
    uploadDocument();
  }, [csvFile]);

  const handleSubmit = async (question) => {
    // console.log("question", question)
    if (question.trim() == "") {
      return;
    }
    setUrlGeneratedData([
      ...urlGeneratedData,
      {
        question: question.trim(),
        answer: "",
      },
    ]);
    setLoading(true);
    let data = JSON.stringify({
      csv_question: question.trim(),
      project: "generativeai-coe",
      location: "us-central1",
      blob_name: blobName,
    });
    let config = {
      method: "post",
      url:
        region == "IND"
          ? "https://us-central1-generativeai-coe.cloudfunctions.net/talk-to-csv"
          : "https://us-central1-generativeai-coe.cloudfunctions.net/talk-to-csv",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data,
    };
    try {
      // console.log("Config", JSON.stringify(config));
      let response = await axios(config);
      // console.log("response", response);
      if (response != undefined) {
        // setGeneratedData([
        //   ...generatedData,
        //   response.data.result,
        // ]);
        setLoading(false);
        setUrlGeneratedData([
          ...urlGeneratedData,
          {
            question: question.trim(),
            answer: response.data,
          },
        ]);
      }
    } catch (err) {
      console.log("Response Error", err);
      setLoading(false);
      setUrlGeneratedData([
        ...urlGeneratedData,
        {
          question: question.trim(),
          answer:
            "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question.",
        },
      ]);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [urlGeneratedData]);

  const handleUrlData = (name) => {
    setBlobName(name);
  };

  const CsvUpload = () => {
    const handleFileSelect = (files) => {
      // perform additional actions with the selected file, like uploading it to a server.
      if (files.length > 0) {
        for (const fl of files) {
          if (!DocNameList.includes(fl.name)) {
            setDocNameList([...DocNameList, fl.name]);
            const reader = new FileReader();
            reader.onload = () => {
              const base64Str = reader.result;
              const splitBase64Strings = base64Str.split(";");
              const splitBase64Str = splitBase64Strings[1].split(",");
              setCsvFile([splitBase64Str[1]]);
            };
            reader.readAsDataURL(fl);
          }
        }
      }
    };

    return (
      <>
        <CCol style={{}}>
          {DocNameList?.map((el, i) => {
            return (
              <CRow>
                <CCol className="mt-1" sm={1}>
                  <CImage src={csvUpload} width={15} height={16}></CImage>
                </CCol>
                <CCol
                  sm={10}
                  key={i}
                  style={{ textOverflow: "ellipsis", color: "#086EB5" }}
                  className="py-1 hover"
                >
                  {el}
                </CCol>
              </CRow>
            );
          })}
        </CCol>

        <div>
          <CRow>
            <CCol
              className="mt-2 d-flex flex-column align-items-center justify-content-center"
              style={{ bottom: "15px", width: "80%" }}
            >
              <a
                style={{
                  color: "#086EB5",
                  border: "1px solid #086EB5",
                  textDecoration: "none",
                  width: "90%",
                }}
                target="_blank"
                href="https://drive.google.com/drive/folders/1pwOVARhucBSRFfx3fc4SbU3N-RVWFkby?usp=drive_link"
                className="hover d-flex gap-2 align-items-center rounded p-1 justify-content-center mb-2"
              >
                View Sample Data {" "}
                <BsFillFolderSymlinkFill
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
              <UploadCsv
                data={DocNameList}
                onFileSelect={handleFileSelect}
                indexed={indexed}
              ></UploadCsv>
            </CCol>
          </CRow>
        </div>
      </>
    );
  };

  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      {/* <CsvSidebar
        setIndexed={setIndexed}
        indexed={indexed}
        setPromptList={setPromptList}
        setGeneratedData={setGeneratedData}
        handleUrlData={handleUrlData}
        setCsvFile={setCsvFile}
        deleteDocument={deleteDocument}
        DocNameList={DocNameList}
        setDocNameList={setDocNameList}
      /> */}
      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CommonSidebar
            generatedData={urlGeneratedData}
            setGeneratedData={setUrlGeneratedData}
            sidebarVis={visible}
            setSidebarVis={setVisible}
            title={"CSV Data Q&A"}
            CustomComponent={<CsvUpload />}
            CComponentTitle={"CSV Upload"}
            askQuestion={true}
            ArcDia={arcDiag}
          />
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <div className="chat-container" ref={messageContainerRef}>
            {urlGeneratedData.length == 0 ? (
              <>
                <div
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: "repeat(1, 1fr)",
                    // gap: 25,
                    width: "80%",
                    marginTop: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="d-flex align-items-center justify-content-center mt-auto"
                >
                  <CImage
                    src={aiImage}
                    width={100}
                    style={{ opacity: "0.6" }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 25,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    width: "80%",
                  }}
                >
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcUpload size={"1.5rem"} /> Upload CSV
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Please provide the CSV Document (under 5MB) in order to
                      address the question adequately.
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcDataSheet size={"1.5rem"} /> Index Data
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Generate information from the submitted CSV Documents.
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      zIndex: 1,
                    }}
                    className="p-2 px-3 scale-up-center"
                  >
                    <b className="d-flex gap-2 align-items-center">
                      <FcSearch size={"1.5rem"} /> Ask Questions{" "}
                    </b>
                    <div className="mt-1 " style={{ color: "light" }}>
                      Ask questions to get the insights from the CSV data.
                    </div>
                  </CCard>
                </div>
              </>
            ) : null}

            {urlGeneratedData?.map((data, index) => {
              return (
                <div>
                  {/* {data.question} */}

                  <ChatMessage
                    key={index}
                    text={data.question}
                    isUser={true}
                    isError={true}
                  />
                  {data.answer.length == 0 ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >
                        {}
                      </CAvatar>
                      <CCol
                        className={` m-0 mt-1 align-items-center `}
                        style={{
                          maxWidth: "91%",
                          backgroundColor: "white",
                          borderRadius: "15px",
                          padding: "5px 10px",
                          marginBottom: "5px",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        }}
                      >
                        <SkeletonTheme
                          baseColor="#d4e8ffb8"
                          highlightColor="#73a9ff"
                        >
                          <h4>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </CCol>
                    </div>
                  ) : (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                        className="font-md mt-2 border avatar"
                        size="sm"
                        src={aiAvatarImage}
                      >
                        {}
                      </CAvatar>
                      <div
                        className={`chat-message other mt-2 align-items-center font-md ms-0`}
                        style={{ maxWidth: "85%" }}
                      >
                        {data.answer.split("\n").map((str) => {
                          return <p className="p-0 m-0">{str}</p>;
                        })}
                      </div>
                    </div>
                  )}
                  {/* <ChatMessage
                    key={index}
                    text={
                      data.answer.length == 0 ? (
                        <div class="custom-loader"></div>
                      ) : (
                        data.answer
                      )
                    }
                    isUser={false}
                    isError={false}
                  /> */}
                </div>
              );
            })}
          </div>
          <CForm
            className="pt-3 new-input-form"
            onSubmit={(e) => {
              e.preventDefault();
              setPromptList([...promptList, e.target[0].value]);
              setPrompt("");
              handleSubmit(e.target[0].value);
            }}
          >
            <div
              className="rolling-chat"
              onClick={() => {
                setIndexed(false);
                setGeneratedData([]);
                setUrlGeneratedData([]);
                setPromptList([]);
                setDocNameList([]);
                deleteDocument();
              }}
            >
              <IoIosChatbubbles size={"2rem"} />
              <span className="font-lg" style={{ fontWeight: 600 }}>
                New Chat
              </span>
            </div>
            <CCard
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "80%",
                padding: "2px",
                borderRadius: "50px",
              }}
            >
              <CCol
                className="d-flex align-items-center w-100 inputfocus m-auto "
                style={{
                  height: "45px",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "50px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <CFormInput
                  className="border-0 inputfocus form-control font-md"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "none",
                    color: "#086EB5",
                    borderRadius: "50px",

                    height: "40px",
                  }}
                  disabled={csvFile.length == 0 || loading}
                  onChange={(e) => {
                    e.preventDefault();
                    setPrompt(e.target.value);
                  }}
                  value={prompt}
                  placeholder={"Ask a question"}
                />
                <CButton
                  type="submit"
                  disabled={prompt == "" || loading}
                  variant="ghost"
                  size="sm"
                  className="p-0 border-0 hover"
                >
                  {loading ? (
                    <CSpinner
                      size="sm"
                      className="m-2 "
                      style={{ color: "#086EB5" }}
                    />
                  ) : (
                    <CIcon
                      icon={cilSend}
                      className="m-2 hover text-success"
                      size="lg"
                    />
                  )}
                </CButton>
              </CCol>
            </CCard>
          </CForm>
          {/* <CForm
            className="pt-3 new-input-form"
            onSubmit={(e) => {
              e.preventDefault();
              setPromptList([...promptList, e.target[0].value]);
              setPrompt("");
              handleSubmit(e.target[0].value);
            }}
          >
            <CCol
              className="d-flex align-items-center w-75 inputfocus m-auto p-2"
              style={{
                borderRadius: "5px",
                height: "35px",
                backgroundColor: "#EEF4F8",
                position: "relative",
              }}
            >
              <CFormInput
                className="border-0 inputfocus form-control"
                style={{
                  backgroundColor: "#EEF4F8",
                  boxShadow: "none",
                  color: "#086EB5",
                  height: "30px",
                  resize: "none",
                }}
                disabled={csvFile.length == 0}
                onChange={(e) => setPrompt(e.target.value)}
                value={prompt}
                placeholder="Ask me something"
              />
              <CButton
                type="submit"
                disabled={prompt == "" || loading}
                variant="ghost"
                className="p-0 border-0 hover"
              >
                {loading ? (
                  <CSpinner
                    size="sm"
                    className="m-2"
                    style={{ color: "#086EB5", height: "20px", width: "20px" }}
                  />
                ) : (
                  <CIcon
                    icon={cilSend}
                    className="m-2 hover"
                    size="lg"
                    style={{ color: "#086EB5" }}
                  />
                )}
              </CButton>
             
            </CCol>
          </CForm> */}
        </CCol>
      </CRow>

      {/* Sidebar */}
    </div>
  );
};

export default CsvPrompt;
