import { cilInfo } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CTooltip } from "@coreui/react";

export const InfoTooltip = ({ info, color }) => {
  return (
    <CTooltip content={info} className="graph-tooltip font-sm" arrow={true}>
      <CIcon
        color={color == "white" ? "white" : "info"}
        className={`${
          color == "white" ? "text-white" : "text-info"
        } position-absolute`}
        width={13}
        height={13}
        style={{ right: "6px", top: "5px", cursor: "pointer" }}
        icon={cilInfo}
      ></CIcon>
    </CTooltip>
  );
};
