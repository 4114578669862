import React, { useState, useRef, useEffect } from "react";
import {
  Stage,
  Layer,
  Transformer,
  Group,
  Text,
  Image as KonvaImage,
  Rect,
} from "react-konva";
import { v4 as uuidv4 } from "uuid";
import { Html } from "react-konva-utils";
import useImage from "use-image";
import ImageElement from "../components/ImageElement";
import { CButton, CCol, CFormTextarea } from "@coreui/react";
import { MdDeleteOutline } from "react-icons/md";

const ImageEditor = (props) => {
  const [backgroundImage] = useImage(props.backgroundImage);
  const transformerRef = useRef(); // Reference for the Transformer
  const textRef = useRef();
  const trRef = useRef();
  const textareaRef = useRef();
  const stageRef = useRef();
  // Handle Image Removal
  const containerRef = useRef(null); // Ref for the container div
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const handleRemoveImage = () => {
    props.setImages(props.images.filter((img) => img.id !== props.selectedId));
    props.setSelectedId(null);
  };
  const handleRemoveText = (id) => {
    const updatedTexts = props.texts.filter((text) => text.id !== id);
    props.setTexts(updatedTexts);
    props.setSelectedTextId(null);
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          const { width, height } = entry.contentRect;
          setStageSize({ width, height });
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);
  const handleAddText = () => {
    const newText = {
      id: uuidv4(),
      text: "Sample Text",
      x: 50,
      y: 50,
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "sans-serif",
      fill: "black",
      width: 200,
      height: 30,
      isEditing: false,
    };
    props.setTexts([...props.texts, newText]);
  };

  const handleDeselect = (e) => {
    if (
      e.target === e.target.getStage() ||
      e.target.getClassName() === "Text"
    ) {
      if (props.selectedId !== null || props.selectedTextId !== null) {
        props.setSelectedId(null);
        props.setSelectedTextId(null);
      }
    }
  };

  const updateText = (id, newProps) => {
    props.setTexts(
      props.texts.map((text) =>
        text.id === id ? { ...text, ...newProps } : text
      )
    );
  };

  const handleStyleChange = (property, value) => {
    if (props.selectedTextId) {
      updateText(props.selectedTextId, { [property]: value });
    }
  };
  const handleExport = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL();
      const link = document.createElement("a");
      link.download = "canvas.png";
      link.href = dataURL;
      link.click();
    }
  };

  // const selectedText = props.texts.find(
  //   (text) => text.id === props.selectedTextId
  // );
  const selectedImage = props.images.find((img) => img.id === props.selectedId);
  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        padding: "0px",
        boxSizing: "border-box",
      }}
    >
      <CCol
        style={{
          backgroundColor: "rgb(139 123 192)",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          position: "relative",
          zIndex: 3000,
          height: "30px",
        }}
        className="w-100 m-0 p-0 px-2 py-2 d-flex align-items-center justify-content-between"
      >
        <span
          style={{
            color: "white",
            fontWeight: "500",
            fontSize: "15px",
          }}
        >
          {" "}
          Image Editor
        </span>{" "}
        <CButton
          color="success"
          className="p-0 px-2"
          style={{ fontSize: "14px" }}
          onClick={() => handleExport()}
        >
          Export
        </CButton>
      </CCol>
      <CCol
        ref={containerRef}
        className=" m-1"
        style={{ border: "1px solid lightgray" }}
      >
        <Stage
          ref={stageRef}
          width={stageSize.width - 60}
          height={stageSize.height - 60}
          onMouseDown={handleDeselect}
        >
          <Layer>
            {/* Background Image */}
            {backgroundImage && (
              <Rect
                x={0}
                y={0}
                width={stageSize.width - 40}
                height={stageSize.height - 40}
                fillPatternImage={backgroundImage}
                fillPatternRepeat="no-repeat"
                fillPatternX={0}
                fillPatternY={0}
                fillPatternScale={{
                  x: Math.min(
                    (stageSize.width - 40) / backgroundImage.width,
                    (stageSize.height - 40) / backgroundImage.height
                  ),
                  y: Math.min(
                    (stageSize.width - 40) / backgroundImage.width,
                    (stageSize.height - 40) / backgroundImage.height
                  ),
                }}
              />
            )}

            <ImageElement
              selectedId={props.selectedId}
              setSelectedId={props.setSelectedId}
              images={props.images}
              onRemoveImage={handleRemoveImage}
            />

            {props.selectedTextId && (
              <Transformer
                ref={transformerRef}
                nodes={selectedImage ? [transformerRef.current] : []}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < 50 || newBox.height < 50) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}

            {props.texts &&
              props.texts.map((text) => (
                <TextElement
                  key={text.id}
                  textRef={textRef}
                  trRef={trRef}
                  textareaRef={textareaRef}
                  textProps={text}
                  isSelected={text.id === props.selectedTextId}
                  onSelect={() => props.setSelectedTextId(text.id)}
                  onChange={(newProps) => updateText(text.id, newProps)}
                  onRemove={handleRemoveText}
                />
              ))}
          </Layer>
        </Stage>
      </CCol>
    </div>
  );
};

const TextElement = ({
  textProps,
  isSelected,
  onSelect,
  onChange,
  textRef,
  trRef,
  textareaRef,
  onRemove,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleDoubleClick = (e) => {
    const stage = e.target.getStage();
    const stageBox = stage.container().getBoundingClientRect();
    const textPosition = e.target.getAbsolutePosition();

    setIsEditing(true);
    setTimeout(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.position = "absolute";
        textarea.style.top = `${textPosition.y + stageBox.top}px`;
        textarea.style.left = `${textPosition.x + stageBox.left}px`;
        textarea.style.fontSize = `${textProps.fontSize}px`;
        textarea.style.fontFamily = textProps.fontFamily;
        textarea.style.color = textProps.fill;
        textarea.style.width = `${textProps.width}px`;
        textarea.value = textProps.text;
        textarea.focus();
      }
    }, 10);
  };

  return (
    <>
      {isEditing ? (
        <Html>
          <textarea
            ref={textareaRef}
            style={{
              border: "1px solid #ccc",
              background: "transparent",
              fontSize: `${textProps.fontSize}px`,
              fontFamily: textProps.fontFamily,
              color: textProps.fill,
            }}
            onBlur={() => {
              setIsEditing(false);
              onChange({ ...textProps, text: textareaRef.current.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Escape") {
                textareaRef.current.blur();
              }
            }}
          />
        </Html>
      ) : (
        <Group draggable onClick={onSelect} onDblClick={handleDoubleClick}>
          <Text
            ref={textRef}
            {...textProps}
            onDragMove={(e) => {
              const newPos = { x: e.target.x(), y: e.target.y() };
              onChange({ ...textProps, ...newPos });
            }}
          />
          {isSelected && <Transformer ref={trRef} />}
        </Group>
      )}
    </>
  );
};

export default ImageEditor;
