import React, { useState } from "react";
import "../styles/image.css";
import { CCol } from "@coreui/react";
import { RiImageEditFill } from "react-icons/ri";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ImageGallery = ({
  id,
  type,
  images,
  selectedImages,
  setSelectedImages,
  setBackgroundImage,
  handleAddImage,
}) => {
  const [loadingStates, setLoadingStates] = useState(images.map(() => true));

  const handleImageSelection = (image) => {
    const isImagePresent = selectedImages.some(
      (selected) => selected.fileName === image.fileName
    );

    if (!isImagePresent) {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const handleImageLoading = (index, value) => {
    console.log(`Image loaded: ${index}`);
    setLoadingStates((prevLoadingStates) => {
      const updatedLoadingStates = [...prevLoadingStates];
      updatedLoadingStates[index] = value;
      return updatedLoadingStates;
    });
  };

  console.log("loadingstate", loadingStates);

  return (
    <div className="gallery">
      {images &&
        images.map((imageUrl, index) => (
          <div
            key={imageUrl.url}
            className="galleryItem d-flex flex-column position-relative"
            onClick={() =>
              id !== "otherImages"
                ? handleImageSelection(imageUrl)
                : type === "backdrop_images"
                ? setBackgroundImage(imageUrl.url)
                : handleAddImage(imageUrl.url)
            }
          >
            {loadingStates[index] && (
              <CCol>
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            )}

            {/* Image Element */}
            <img
              src={imageUrl.url}
              alt={imageUrl.fileName}
              width="100%"
              height="auto"
              style={{
                display: loadingStates[index] ? "none" : "block",
              }}
              onLoadStart={() => handleImageLoading(index, true)}
              onLoadCapture={() => handleImageLoading(index, false)}
            />
          </div>
        ))}
    </div>
  );
};

export default ImageGallery;
