import React from "react";
import { Bars } from "react-loader-spinner";

const BarLoader = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Bars
        height={props.height}
        width={props.width}
        color="#838383"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default BarLoader;
