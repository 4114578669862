import React, { useState, useRef, useEffect } from "react";
import { CCol, CRow } from "@coreui/react";
import axios from "axios";
import { Navbar } from "../components/Navbar";
import "../styles/chat.css";
import { IoLogoBuffer, IoMdImages } from "react-icons/io";

import ImageGenerator from "../components/ImageGenerator";
import OtherImageGallary from "../components/OtherImageGallary";
import { IoClose } from "react-icons/io5";
import ImageChat from "../components/ImageChat";
import ImageEditor from "../components/ImageEditor";
import TextToolbar from "../components/TextToolbar";
import { PiTextTBold } from "react-icons/pi";
// import { AiOutlineProduct } from "react-icons/ai";
import { PiSelectionBackgroundDuotone } from "react-icons/pi";
import { GiClothes } from "react-icons/gi";
import { GiSofa } from "react-icons/gi";
import { RiEmojiStickerLine } from "react-icons/ri";
// import { TbBackground } from "react-icons/tb";
const Tabs = [
  ["AI Image Generato", "ImageGenerator", IoMdImages],
  ["Backdrop Images", "backdrop_images", PiSelectionBackgroundDuotone],
  ["Fashion", "fashion", GiClothes],
  ["Logo Images", "logo_images", IoLogoBuffer],
  ["Furniture", "furniture", GiSofa],
  ["Text", "Text", PiTextTBold],
];
const ChromaChat = () => {
  const region = JSON.parse(sessionStorage.getItem("region"));

  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [visible, setVisible] = useState(true);
  const messageContainerRef = useRef(null);
  const [generatedData, setGeneratedData] = useState([]);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [imageCount, setImageCount] = useState(4);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [otherImages, setOtherImages] = useState([]);
  const [selectedTab, setSelectedTab] = useState("ImageGenerator");
  const [selectedTextId, setSelectedTextId] = useState(null);
  const [texts, setTexts] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  const handlePrompt = async (e) => {
    e.preventDefault();
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    let query = inputPrompt.toLowerCase().trim();
    if (
      query == "hi" ||
      query == "hello" ||
      query == "what can you do for me" ||
      query == "helo" ||
      query == "hii" ||
      query == "hey" ||
      query == "how are you"
    ) {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "hello" },
      ]);
      setInputVal("");
    } else {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "" },
      ]);
      setInputVal("");
      setLoading(true);
      getUnstructured(inputPrompt);
    }
  };

  const getUnstructured = async (inputPrompt) => {
    let config = {
      method: "post",
      url:
        region == "IND"
          ? ""
          : "https://us-central1-generativeai-coe.cloudfunctions.net/croma_image_generation",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        prompt: inputPrompt,
        number_of_images: Number(imageCount),
        aspect_ratio: aspectRatio,
      },
    };

    let config1 = {
      method: "post",
      url:
        region == "IND"
          ? ""
          : "https://us-central1-generativeai-coe.cloudfunctions.net/croma_signed_url",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name: "croma_images",
        folder_name: "temp",
      },
    };
    try {
      // let response = tempResp;
      let response = await axios(config);
      if (response != undefined) {
        console.log(response);
        if (response.data.generated_images == "Succesfully Generated") {
        }
        const signedResponse = await axios(config1);
        console.log("signedResponse", signedResponse);
        if (signedResponse != undefined) {
          setLoading(false);
          setGeneratedData([
            ...generatedData,
            {
              question: inputPrompt,
              summary: "generated data",
              generated_image: signedResponse.data.signed_urls,
            },
          ]);
        }
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          summary: "Answer Not Found",
          img_search_data: [],
          tuning_data: "",
          results: "",
        },
      ]);
    }
  };

  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      <CRow className="p-2 " xs={{ gutter: "5px" }}>
        <CCol className="ms-2 p-0 pt-2 side-tab-container">
          {Tabs.map(([label, ID, TabIcon]) => (
            <CCol
              className={` side-tab py-2 ${
                selectedTab == ID && "side-tab-active"
              }`}
              onClick={() => setSelectedTab(ID)}
            >
              <TabIcon size={20} />
              <span>{label}</span>
            </CCol>
          ))}
        </CCol>
        <CCol
          className="me-2 p-0 side-tab-nested-container "
          style={{ overflow: "hidden" }}
        >
          <CCol className="w-100 d-flex align-items-center justify-content-between px-2 py-1 side-tab-nested-content m-0">
            {selectedTab == "ImageGenerator"
              ? "AI Image Generator"
              : selectedTab == "backdrop_images"
              ? "Backdrop Images"
              : selectedTab == "fashion"
              ? "Product Images"
              : selectedTab == "logo_images"
              ? "Logo Images"
              : selectedTab == "furniture"
              ? "Furniture"
              : "Text"}
            <IoClose size={20} />
          </CCol>
          {selectedTab == "ImageGenerator" ? (
            <ImageGenerator
              handlePrompt={handlePrompt}
              inputVal={inputVal}
              generatedData={generatedData}
              setInputVal={setInputVal}
              loading={loading}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              aspectRatio={aspectRatio}
              setAspectRatio={setAspectRatio}
              imageCount={imageCount}
              setImageCount={setImageCount}
            />
          ) : selectedTab == "Text" ? (
            <TextToolbar
              texts={texts}
              setTexts={setTexts}
              selectedTextId={selectedTextId}
              setSelectedTextId={setSelectedTextId}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              selectedText={
                texts &&
                texts.find(
                  (text) => text.id === selectedTextId && text.type == "text"
                )
              }
            />
          ) : (
            <OtherImageGallary
              type={selectedTab}
              setBackgroundImage={setBackgroundImage}
              otherImages={otherImages}
              setOtherImages={setOtherImages}
            />
          )}
        </CCol>
        <CCol className="p-0 side-tab-main-content">
          {selectedTab == "ImageGenerator" ? (
            <ImageChat
              handlePrompt={handlePrompt}
              inputVal={inputVal}
              setInputVal={setInputVal}
              generatedData={generatedData}
              loading={loading}
              messageContainerRef={messageContainerRef}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
            />
          ) : (
            <ImageEditor
              backgroundImage={backgroundImage}
              images={otherImages}
              setImages={setOtherImages}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              texts={texts}
              setTexts={setTexts}
              selectedTextId={selectedTextId}
              setSelectedTextId={setSelectedTextId}
            />
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export default ChromaChat;
